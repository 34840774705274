<!-- 属性 -->
<script setup>
import { reactive, watchEffect } from 'vue';
import useMarketStore from '@store/cattleMarketStore';
import { lowest, hightest, attrsList } from './useAttrList';
const marketStore = useMarketStore();

const activeIds = 1;

watchEffect(() => {
  attrsList[0].disable = false;
  attrsList[1].disable = false;
  attrsList[2].disable = false;

  if (marketStore.filterTag.pickGender === 0) {
    // 选择了公牛和母牛, 特殊属性置灰
    attrsList[1].disable = true;
    attrsList[2].disable = true;
  } else if (marketStore.filterTag.pickGender === 1) {
    // 选择了公牛，产奶属性置灰
    attrsList[2].disable = true;
  } else if (marketStore.filterTag.pickGender === 2) {
    // 选择了母牛，攻击属性置灰
    attrsList[1].disable = true;
  }
});

const handleChange = () => {
  const defalutVal = [lowest, hightest]; // 默认值
  /**
   * 基础属性
   */
  const base = attrsList[0].children;
  const life = base[0].checked ? base[0].range : defalutVal; // 取生命值
  const growth = base[1].checked ? base[1].range : defalutVal; // 取成长值
  const energy = base[2].checked ? base[2].range : defalutVal; // 取体力值

  /**
   * 战斗属性
   */
  const battle = attrsList[1].children;
  const attack = battle[0].checked ? battle[0].range : defalutVal; // 取战斗值
  const stamina = battle[1].checked ? battle[1].range : defalutVal; // 取耐力值
  const defense = battle[2].checked ? battle[2].range : defalutVal; // 取防御值

  /**
   * 产奶属性
   */
  const milking = attrsList[2].children;
  const milk = milking[0].checked ? milking[0].range : defalutVal; // 取产奶值
  const milkRate = milking[1].checked ? milking[1].range : defalutVal; // 取产奶速率

  console.log(life, growth, energy, attack, stamina, defense, milk, milkRate);

  marketStore.setAttrsParams({
    life,
    growth,
    energy,
    attack,
    stamina,
    defense,
    milk,
    milkRate
  });
};
</script>

<template>
  <el-collapse v-model="activeIds" class="attribute-wrap panel-container">
    <!-- 基础属性 -->
    <el-collapse-item v-for="attr in attrsList" :key="attr.id" :name="attr.id">
      <!-- 标题 -->
      <template #title>
        <div class="panel-header">
          <img src="@img/common/icon-folder.png" alt="" class="icon-folder" />
          <div>{{ $t(attr.title) }}</div>
        </div>
      </template>
      <!-- 内容 -->
      <template v-for="child in attr.children" :key="child.id">
        <van-checkbox
          :disabled="marketStore.curPickCate !== 'calf' || attr.disable"
          checked-color="#fff"
          v-model="child.checked"
          @change="handleChange(child)"
        >
          <template #icon="props">
            <i v-show="child.checked" class="iconfont icon-green-tick"></i>
            <i v-show="!child.checked" class="iconfont icon-fangkuang"></i>
          </template>
          <span class="name">{{ $t(child.name) }}</span>
        </van-checkbox>
        <!-- <el-slider v-model="child.val" @change="handleChange" :min="60"> </el-slider> -->
        <van-slider
          bar-height="8"
          v-model="child.range"
          range
          active-color="#35DAFF"
          :min="lowest"
          :max="hightest"
          @change="handleChange(child, $event)"
          :disabled="marketStore.curPickCate !== 'calf' || attr.disable"
        >
          <template #left-button>
            <div class="slide-btn">
              <div class="top-num" v-show="child.range[0] !== lowest">
                {{ child.range[0] }}
              </div>
            </div>
          </template>
          <template #right-button>
            <div class="slide-btn">
              <div class="top-num" v-show="child.range[1] !== hightest">
                {{ child.range[1] }}
              </div>
            </div>
          </template>
        </van-slider>
        <div class="num">
          <div>{{ lowest }}</div>
          <div>{{ hightest }}</div>
        </div>
      </template>
    </el-collapse-item>
  </el-collapse>
</template>

<style lang="scss" scoped>
@import './index.scss';
.attribute-wrap {
  // 多选框名字
  .name {
    font-size: 0.2rem;
    color: #fff;
    @include -media($phone, font-size, 0.4rem);
    @include -media($phone, line-height, 0.47rem);
  }

  // 滑块
  :deep(.van-slider) {
    width: 93%;
    margin: 0.27rem auto 0.1rem;
    background-color: rgba($color: #fff, $alpha: 0.2);
  }

  // 多选框图标
  .iconfont {
    font-size: 0.2rem;
    @include -media($phone, font-size, 0.4rem);
  }

  :deep(.van-checkbox ){
      @include -media($phone, font-size, 0.36rem);
      padding-bottom: 0.1rem;
      @include -media($phone, margin, 0.2rem 0 0.17rem 0);
      i {
        @include -media($phone, font-size, 0.36rem);
      }
    }

  :deep(.van-checkbox--disabled) {
    
      opacity: 0.5;
  }
}

// 滑块下方两边的数字
.num {
  width: 100%;
  @include flexPos(space-between, center);
  color: #fff;
  margin: 0.05rem 0 0.3rem;
}

.top-num {
  @include flexPos(space-between);
  color: #fff;
  margin: 0.1rem 0 0.6rem;
  padding: 0 0.05rem;
  background-color: rgba($color: #fff, $alpha: 0.4);
  border-radius: 2px;
  @include -media($phone, margin, 0.1rem 0 0.9rem);
}

// 滑块的块
.slide-btn {
  @include -width(0.3rem, 0.4rem, 0.2rem);
  @include -height(0.3rem, 0.4rem, 0.2rem);
  background-color: #35daff;
  color: #fff;
  @include flexPos(center);
  user-select: none;
  border-radius: 0.39rem;

  // 滑块上面的数字
  .num {
    // background-color: pink;
    transform: translateY(-60%);
  }
}

el-collapse-item {
  margin: 100px 0;
}
</style>
<style lang="scss"></style>
