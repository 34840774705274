<!-- 商品列表 -->
<script setup>
import useMarketStore from '@store/cattleMarketStore';
import { computed } from 'vue';
import GridItem from './CardItem.vue';
const marketStore = useMarketStore();

/**
 * 当前展示的卡牌列表
 */
console.log(marketStore.curPickCate, 'marketStore.curPickCate');
console.log(marketStore.blindBoxList, 'marketStore.blindBoxList');
const cardList = computed(() => {
  // 当前形式勾选的是盲盒
  if (marketStore.curPickCate === 'blindBox') {
    return marketStore.blindBoxList || [];
  }

  // 当前形式勾选的是牛牛
  if (marketStore.curPickCate === 'calf') {
    console.log('calfs...', marketStore.cattleList);
    return marketStore.cattleList || [];
  }

  // 当前形式勾选的是星球
  if (marketStore.curPickCate === 'planet') {
    return marketStore.planetList || [];
  }

  return [];
});
</script>

<template>
  <div class="grid-wrap">
    <!-- 国庆前关闭（已开启） -->
    <GridItem :cardItem="item" v-for="(item, index) in cardList" :key="index" />
  </div>
  <van-empty
    v-if="!cardList.length"
    class="empty"
    :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
    :description="$t('panel.34')"
  />
</template>

<style lang="scss" scoped>
.grid-wrap {
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0.4rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 0.42rem;
  grid-template-columns: repeat(auto-fit, 2.2rem);
  @include -media($phone, grid-template-columns, auto auto);
  @include -media($pc, padding-left, 0);
  .card-wrap {
    margin: 0 auto;
    width: 220px;
    // height: 320px;
    border: 1px solid rgba(36, 48, 62, 1);
    border-radius: 6px;
    cursor: pointer;

    @media (max-width: $phone) {
      width: 3.15rem;
      // height: 3.8rem;
    }
  }
}
.empty {
  height: 100%;
  width: 60vw;
  margin: 0 auto;
  @include flexPos(center);
  flex-direction: column;
}
:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  // margin-left: 45%;
  @include flexPos(center);
  flex-direction: column;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}
</style>
