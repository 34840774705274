<!-- 筛选 组件 -->
<script setup>
import { useRoute } from 'vue-router';
import $router from '@/router';
import { computed, nextTick, reactive, ref, watchEffect, watch, onMounted } from 'vue';
import useMarketStore from '@store/cattleMarketStore';
import { resetAttr } from './useAttrList';
const marketStore = useMarketStore();


console.log("marketStore",marketStore);
console.log("marketStore.curPickGender",marketStore.curPickGender);
const route = useRoute();

const filterList = reactive([
  {
    id: 1,
    title: 'panel.23',
    logo: 'icon-relation',
    children: [
      {
        id: 1,
        checked:true,
        hide: marketStore.curPickCate !== 'calf',
        name: 'card.3',
        eName: 'createCattle'
      },
      {
        id: 2,
        checked: true,
        hide: marketStore.curPickCate !== 'calf',
        name: 'card.4',
        eName: 'normalCattle'
      }
    ]
  },
  {
    id: 2,
    title: 'panel.5',
    logo: 'icon-gender',
    children: [
      {
        id: 2,
        checked: true,
        hide: marketStore.curPickCate !== 'calf',
        name: 'panel.26',
        eName: 'cow'
      },
      {
        id: 1,
        checked: true,
        hide: marketStore.curPickCate !== 'calf',
        name: 'panel.25',
        eName: 'bull'
      }
    ]
  },
  {
    id: 3,
    title: 'panel.22',
    logo: 'icon-form',
    children: [
      {
        id: 0,
        checked: marketStore.curPickCate === 'blindBox',
        name: 'panel.18',
        eName: 'blindBox'
      },
      {
        id: 1,
        checked: marketStore.curPickCate === 'calf',
        name: 'panel.17',
        eName: 'calf'
      },
      {
        id: 2,
        checked: marketStore.curPickCate === 'planet',
        name: 'panel.8',
        eName: 'planet'
      }
    ]
  }, {
    // 种对应：
// 1：OXG
// 2：OXX
// 4：USDT
// 5：AIA
    id: 4,
    title: 'panel.36',
    logo: 'icon-form',
    children: [
      {
        id: 1,
        checked: marketStore.curPickCoins.includes(1),
        name: 'panel.18',
        text:'OXG',
        eName: 'OXG'
      },
      {
        id: 2,
        checked: marketStore.curPickCoins.includes(2),
        name: 'panel.17',
        text:'OXX',
        eName: 'OXX'
      },
      {
        id: 4,
        checked: marketStore.curPickCoins.includes(4),
        name: 'panel.8',
        text:'USDT',
        eName: 'USDT'
      },
       {
        id: 5,
        checked: marketStore.curPickCoins.includes(5),
        name: 'panel.8',
        text:'AIA',
        eName: 'AIA'
      }
    ]
  }
]);

function init(){
  if(marketStore.curPickCate == "calf"){
    if(marketStore.curPickGender == 1){ //没勾选母牛
      filterList[1].children[0].checked = false
    }else if(marketStore.curPickGender == 2){ //没勾选公牛
      filterList[1].children[1].checked = false
    }

    if(marketStore.curPickBlood == 1){//没勾选创世
      filterList[0].children[0].checked = false
    }else if(marketStore.curPickBlood == 2){//没勾选普通牛牛
      filterList[0].children[1].checked = false
    }
  }
}
init()

const lock = ref(false);
const lockBlood = ref(false);
const lockGender = ref(false);



watch(
  () => marketStore.curPickCate,
  (value, oldValue) => {
    // 勾选了盲盒 或者 星球, 血统和性别所有选项隐藏
    const _fids = [3,4]
    if (marketStore.curPickCate === 'blindBox' || marketStore.curPickCate === 'planet') {
      console.log('......')
      filterList.forEach(f => {
        
        if (!_fids.includes(f.id)) {
          console.log(f.id)
          // 除了形式那一栏，血统和性别都置灰
          f.children.forEach(child => {
            child.hide = true;
          });
        } else {
          // 勾选 盲盒 或者 星球
          f.children.forEach(child => {
            if (!lock.value && route?.query?.type === child.eName) {
              lock.value = true;
              // selectList(f, child);
            }
            // 首次进入交易市场时默认盲盒选项
            if (!lock.value && !route?.query?.type) {
              lock.value = true;
              // selectList(f, child);
            }
          });
        }
      });
    } else {
      filterList.forEach(f => {
        if (!_fids.includes(f.id)) {
          // 选项全部开放
          f.children.forEach(child => {
            child.hide = false;
            // 判断 如果是 牛， 获取上一轮的 血统 和 性别
            if (!lockBlood.value && f.id == 1 && child.id == marketStore.curPickBlood) {
              // 血统 反向选择
              lockBlood.value = true;
              child.checked = true;
              if (marketStore.curPickBlood == 1) {
                filterList[0].children[1].checked = false;
              } else if (marketStore.curPickBlood == 2) {
                filterList[0].children[0].checked = false;
              }
            } else if (!lockGender.value && f.id == 2 && child.id == marketStore.curPickGender) {
              // 性别 反向选择
              child.checked = true;
              lockGender.value = true;
              if (marketStore.curPickGender == 1) {
                filterList[1].children[0].checked = false;
              } else if (marketStore.curPickGender == 2) {
                filterList[1].children[1].checked = false;
              }
            }
          });
        } else {
          // 勾选 牛
          f.children.forEach(child => {
            if (!lock.value && child.eName === 'calf') {
              lock.value = true;
              // selectList(f, child);
            }
          });
        }
      });
    }
  }
);

/**
 * 获取卡片列表数据
 * @param {Number} cate 选择的分类：0盲盒，1牛牛，2星球
 * @param {Number} bloodline 血统：0全部，1创世，2普通
 * @param {Number} gender 性别：0全部，1公牛；2母牛
 */
function fetchCardList(cate, bloodline, gender) {
  if (cate === 0) {
    // 形式选择 盲盒
    marketStore.setFilterParams({
      class: bloodline,
      gender
    });
    return;
  }

  if (cate === 1) {
    // 形式选择 牛牛
    marketStore.setFilterParams({
      class: bloodline,
      gender
    });
    return;
  }

  if (cate === 2) {
    // 形式选择 星球
    marketStore.setFilterParams({
      class: bloodline,
      gender
    });
    return;
  }
}

/**
 * 处理勾选 筛选 面板
 * @param {Object} f 大分类：血统、性别、形式
 * @param {Object} child 小分类：大分类里面的各个子分类
 */
function selectList(f, child) {
  // 已禁用的按钮不触发
  if (child.hide) {
    return;
  }

  // let bloodline = 0; // 血统
  // let gender = 0; // 性别
  let cate = 0; // 形式: 0:盲盒、1:牛牛、2:星球

  if (f.id === 1) {
    /**
     * 选择血统
     */
    child.checked = !child.checked;
    let count = 0;
    for (let i = 0, len = f.children.length; i < len; i++) {
      const it = f.children[i];
      if (it.checked) {
        // 单选了某个
        marketStore.curPickBlood = it.id;
        count++;
      }
    }
    if (count === f.children.length || count === 0) {
      // 全选 or 全不选 状态
      marketStore.curPickBlood = 0;
    }
  } else if (f.id === 2) {
    /**
     * 选择性别
     */

    child.checked = !child.checked;
    // 单选性别；不能为不选，多选为0
    const genders = f.children.filter(child => {
      return child.checked;
    });
    if (!genders.length) {
      child.checked = !child.checked;
      return;
    }

    let count = 0;
    for (let i = 0, len = f.children.length; i < len; i++) {
      const it = f.children[i];
      if (it.checked) {
        // 单选了某个
        marketStore.curPickGender = it.id;
        count++;
      }
    }

    if (count === f.children.length || count === 0) {
      // 全选 or 全不选 状态
      marketStore.curPickGender = 0;
    }
  } else if (f.id === 3) {
    /**
     * 选择形式
     */
    const cateList = f.children;
    // curPickCate记录保存到url上 详情页返回保存状态
    $router.push({ query: { curPickCate: child.eName } });
    cateList.forEach(kid => {
      kid.checked = false;
      if (kid.id === child.id) {
        kid.checked = true;
        cate = child.id;
        marketStore.setCurPickCate(child.eName);
      }
    });
    //重置基础属性滑块
    resetAttr();
  } else if(f.id == 4) {
    
    child.checked = !child.checked
    // 选择币种
    marketStore.setCurpickCoins(child)
    console.log('length==>',marketStore.curPickCoins.length)
    if(marketStore.curPickCoins.length == 1) {
      f.children.forEach(item =>{
        item.hide = item.checked
      })
    } else {
      f.children.forEach(item =>{
        item.hide = false
      })
    }
  }
  // console.log(bloodline, gender, cate);

  fetchCardList(cate, marketStore.curPickBlood, marketStore.curPickGender, marketStore.curPickCoins);
}

/**
 * 清空所有过滤选择
 */
function handleClear() {
  marketStore.calfFetchParam = {
    ...marketStore.calfFetchParam,
    life: [5000, 15000], // 生命，数组长度2。不发就是查全部
      growth: [5000, 15000], // 成长，数组长度2。不发就是查全部
      energy: [5000, 15000], // 体力，数组长度2。不发就是查全部
      attack: [5000, 15000], // 战斗(公牛)，数组长度2。不发就是查全部
      stamina: [5000, 15000], // 耐力(公牛)，数组长度2。不发就是查全部
      defense: [5000, 15000], // 防御(公牛)，数组长度2。不发就是查全部
      milk: [5000, 15000], // 产奶量(母牛)，数组长度2。不发就是查全部
      milkRate: [5000, 15000],
    
  }
  const _fids = [3,4]
  if (marketStore.curPickCate === 'blindBox' || marketStore.curPickCate === 'planet') {
    filterList.forEach(f => {
      if (f.id !== 3) {
        console.log(f.id)
        // 除了形式那一栏，血统和性别都置灰
        f.children.forEach(child => {
          if(f.id == 4) {
            child.hide = false
          } else {
            child.hide = true;
          }
          
          child.checked = true;
        });
       
        marketStore.curPickBlood = 0;
        marketStore.curPickGender = 0;
        marketStore.curPickCoins = [1,2,4,5]
      }
    });
    marketStore.publicParams.sort = 0;
    marketStore.publicParams.page = 1;
    const cate = marketStore.curPickCate === 'blindBox' ? 0 : 2;
    fetchCardList(cate, 0, 0);
  } else {
    // 选择牛牛类别
    filterList.forEach(f => {
      if (f.id !== 3) {
        // 选项全部开放
        f.children.forEach(child => {
          child.hide = false;
          child.checked = true;
        });
        marketStore.curPickBlood = 0;
        marketStore.curPickGender = 0;
        marketStore.curPickCoins = [1,2,4,5]
      }
    });
    marketStore.publicParams.sort = 0;
    marketStore.publicParams.page = 1;
    // 获取牛牛
    fetchCardList(1, 0, 0);
  }
  
  //重置基础属性滑块
  resetAttr();
}
</script>

<template>
  <div class="filter-wrap panel-container">
    <div class="header-container">
      <div class="panel-header">
        <img src="" alt="" />
        {{ $t('panel.20') }}
      </div>
      <div class="panel-clear" @click="handleClear">{{ $t('panel.21') }}</div>
    </div>
    <!-- <el-checkbox-group v-for="(f, inx) in panelStore.filterList" :key="inx" v-model="panelStore.filterList"> -->
    <div v-for="(f, inx) in filterList" :key="inx" style="margin-top: 0.2rem">
      <div class="panel-title">
        <span>{{ $t(f.title) }}</span>
      </div>
      <!-- 血统、性别、形式、币种 -->
      <div class="list">
        <van-checkbox
          checked-color="#fff"
          v-for="(child, i) in f.children"
          :key="i"
          @click="selectList(f, child)"
          :disabled="child.hide"
        >
          <template #icon="props">
            <i v-show="child.checked" class="iconfont icon-green-tick"></i>
            <i v-show="!child.checked" class="iconfont icon-fangkuang"></i>
          </template>
          <span style="color: #fff">{{child.text ||  $t(child.name) }}</span>
        </van-checkbox>
      </div>
    </div>

     

    <!-- </el-checkbox-group> -->
  </div>
</template>

<style lang="scss" scoped>
@import './index.scss';

.filter-wrap {
  // background-color: plum;
  border-bottom: solid 1.5px #333;
  margin-bottom: 0.4rem;
  padding-bottom: 0.3rem;

  /* :deep(.el-checkbox) {
    margin-left: 0.25rem;
  } */

  .panel-title {
    @include flexPos(flex-start, center);
    // width: 0.95rem;
    height: 0.36rem;
    @include -margin-bottom(0.2rem, 0.2rem, 0.1rem);

    border-radius: 4px;
    font-size: 0.24rem;
    @include -media($phone, font-size, 0.48rem);

    .iconfont {
      margin-right: 0.1rem;
      font-size: 0.2rem;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.17rem;

    :deep() {
      .van-checkbox {
        font-size: 0.2rem;
        margin: 0.1rem 0.1rem 0.1rem 0;
        width: 1.6rem;
        @include -media($phone, font-size, 0.36rem);
        @include -media($phone, width, 3.2rem !important);
        @include -media($phone, margin, 0.16rem 0.01rem 0.2rem 0);
        i {
          @include -media($phone, font-size, 0.36rem);
        }
      }

      // disable 置灰
      .van-checkbox--disabled {
        div,
        span {
          // color: rgba(255, 255, 255, 0.5) !important;
          opacity: 0.5;
          @include -media($phone, font-size, 0.36rem);
        }
        i {
          @include -media($phone, font-size, 0.36rem);
        }
      }
    }

    .iconfont {
      font-size: 0.2rem;
    }
  }

  .header-container {
    @include flexPos(space-between);
    .panel-header {
      margin-bottom: 0.15rem;
      @include -media($phone, font-size, 0.6rem);
    }
    .panel-clear {
      font-size: 0.2rem;
      margin-bottom: 0.15rem;
      @include -media($phone, font-size, 0.36rem);
      cursor: pointer;
    }
  }
}
</style>
