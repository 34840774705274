<!-- nft市场 -->
<script setup>
import { ref } from 'vue';
import PanelComp from './components/Panel';
import NavComp from './components/Nav.vue';
import Production from './components/Production';
import $router from '@/router';
import { useRoute } from 'vue-router';
import useMarketStore from '@store/cattleMarketStore';
import { useAppStore } from '@store/appStore';

const marketStore = useMarketStore();
const appStore = useAppStore();
const $route = useRoute();

const isShowMobPanel = ref(false);

/**
 * 首次加载，默认请求盲盒数据
 */
function init() {
  const curPickCate = $route.query.curPickCate;
  if (curPickCate) {
    marketStore.setCurPickCate(curPickCate);
  }
  marketStore.handlePublicFetch();
}
init();
</script>

<template>
  <div :class="['nft-market-wrap', { mob: appStore.curDevice === 'phone' }]">
    <!-- <TestComp /> -->

    <!-- 移动端的侧边栏，滑动弹出面板 -->
    <transition name="market-panel">
      <div class="panel-container" v-if="isShowMobPanel">
        <PanelComp />

        <div class="btns">
          <button @click="isShowMobPanel = false">{{ $t('dialog.1') }}</button>
        </div>
      </div>
    </transition>

    <transition name="market-main">
      <section class="main" v-if="appStore.curDevice === 'phone' && !isShowMobPanel">
        <!-- 商品列表 -->
        <section class="product-list">
          <Production />
        </section>
      </section>
    </transition>

    <!-- 导航栏 -->
    <!-- <NavComp /> -->
    <div class="nav-wrap" v-if="appStore.curDevice !== 'phone'">
      <img
        src="https://game.legendranch.app/legendaryranch/home/gameFi/logo-star-farm.png"
        alt=""
        class="nav-img"
      />
    </div>

    <!-- 内容区 -->
    <section class="main" v-if="appStore.curDevice !== 'phone'">
      <!-- 侧边选择面板 -->
      <aside>
        <PanelComp />
      </aside>

      <!-- 商品列表 -->
      <section class="product-list">
        <Production />
      </section>
    </section>

    <!-- 呼出面板 -->
    <div class="btns" v-if="appStore.curDevice === 'phone' && !isShowMobPanel">
      <button @click="isShowMobPanel = true">{{ $t('panel.20') }}</button>
      <button @click="$router.push({ name: 'myListing' })">{{ $t('panel.27') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel-container {
  position: fixed;
  left: 0;
  top: $mobTopBarHeight;
  width: 100%;
  height: calc(100vh - $mobTopBarHeight);
  overflow: auto;
  // padding-left: 0.5rem;
  padding-bottom: 4.36rem;
  @include -media($phone, padding-bottom, 0.5rem) {
    position: relative;
    left: 0;
    top: 0;
  }
  .btns {
    margin-bottom: 0 !important;

    button {
      width: 100% !important;
    }
  }
}

.nft-market-wrap {
  $navHeight: 1.5rem; // tab导航栏高度
  $asideWidth: 4.54rem; // 侧边栏宽度

  @include nftImgBg;

  &.mob {
    border-bottom: solid 0.01px transparent;
    height: calc(100vh - $mobTopBarHeight);
  }

  color: #fff;

  > .nav-wrap {
    height: $navHeight;
    padding-left: 0.43rem;
    border-bottom: solid 1.5px #333;
    @include flexPos(flex-start, center);

    .nav-img {
      width: 2.63rem;
    }
  }

  > .main {
    display: flex;
    // height: calc(100vh - #{$mainHeight});

    > aside {
      flex: 0 0 $asideWidth;
      height: calc(#{$mainHeight} - #{$navHeight});
      overflow: auto;
      border-right: solid 1px #3f354f;

      @media (max-width: $pad) {
        height: calc(#{$mobMainHeight} - #{$navHeight});
      }
    }

    > .product-list {
      flex: auto;
      padding-left: 1.26rem;

      height: calc(#{$mainHeight} - #{$navHeight});
      overflow: auto;
      @media (max-width: $pad) {
        height: calc(#{$mobMainHeight} - #{$navHeight});
      }
    }

    @media screen and (max-width: 1440px) {
      > .product-list {
        padding-left: 0;

        :deep(.sale-num) {
          font-size: 0.3rem;
        }
      }
    }
  }

  // 呼出面板按钮
  .btns {
    @include flexPos(space-between);
    padding: 0 0.48rem;

    button {
      width: 2.96rem;
      height: 0.7rem;
      background-color: #5b3bb7;
      border-radius: 0.06rem;
      font-size: 0.28rem;
    }

    // margin-bottom: 4.7rem;
  }
}
</style>
