import { reactive } from 'vue';

export const lowest = 5000; // 最低值
export const hightest = 15000; // 最高值

// 属性列表
export const attrsList = reactive([
  {
    id: 1,
    title: 'detail.2',
    active: true,
    disable: false, // 是否置灰
    children: [
      {
        id: 1,
        checked: true,
        name: 'detail.5',
        range: [lowest, hightest],
        target: 'life'
      },
      {
        id: 2,
        checked: true,
        name: 'detail.6',
        range: [lowest, hightest],
        target: 'growth'
      },
      {
        id: 3,
        checked: true,
        name: 'detail.7',
        range: [lowest, hightest],
        target: 'energy'
      }
    ]
  },
  {
    id: 2,
    title: 'detail.3',
    active: true,
    disable: false, // 是否置灰
    children: [
      {
        id: 1,
        checked: true,
        name: 'detail.8',
        range: [lowest, hightest],
        target: 'attack'
      },
      {
        id: 2,
        checked: true,
        name: 'detail.9',
        range: [lowest, hightest],
        target: 'stamina'
      },
      {
        id: 3,
        checked: true,
        name: 'detail.10',
        range: [lowest, hightest],
        target: 'defense'
      }
    ]
  },
  {
    id: 3,
    title: 'detail.4',
    active: true,
    disable: false, // 是否置灰
    children: [
      {
        id: 1,
        checked: true,
        name: 'detail.11',
        range: [lowest, hightest],
        target: 'milk'
      },
      {
        id: 2,
        checked: true,
        name: 'detail.12',
        range: [lowest, hightest],
        target: 'milkRate'
      }
    ]
  }
]);

//重置基础属性滑块
export function resetAttr() {
  attrsList.forEach(attr => {
    attr?.children.forEach(item => {
      item.range[0] = lowest;
      item.range[1] = hightest;
      item.checked = true;
    });
  });
}

//选择牛牛的时候
// export function changeCalf()
