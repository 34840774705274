<!-- 商品容器 -->
<script setup>
import { onMounted, ref, watch, nextTick } from 'vue';
import Grid from './Grid';
import useMarketStore from '@store/cattleMarketStore';
import { useAppStore } from '@store/appStore';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n.js';
import { useRouter } from 'vue-router';

const $t = i18n.global.t;
const marketStore = useMarketStore();

const appStore = useAppStore();
const $router = useRouter();

/**
 * 下拉框（价格升降序，最新时间）
 */
const options = [
  {
    value: 0, // 价格升序
    label: 'panel.29',
  },
  {
    value: 1, // 价格降序
    label: 'panel.28',
  },
  {
    value: 2, // 最新时间排序
    label: 'panel.30',
  },
];

const showSelPanel = ref(false); // 是否显示下拉框面板

/**
 * 鼠标移入状态栏
 */
function enterTrade() {
  showSelPanel.value = true;
}

/**
 * 鼠标移出状态栏
 */
function leaveTrade() {
  showSelPanel.value = false;
}

/**
 * 下拉排序列表触发
 * @param {Object} opt 选项框的每一项
 */
function selectChange(opt) {
  // {Number} sort 0:价格升序，1:价格降序，2:最新时间排序
  marketStore.setSelectParams(opt.value);
}

/**
 * 分页查询
 * @param {Number} page 页码
 */
function changePage(page) {
  marketStore.setPageParams(page);
}

/**
 * 去我的挂单页面
 */
function handleOrder() {
  if (!appStore.defaultAccount) {
    ElMessage({
      message: $t('msg.22'),
      type: 'error',
    });
    return;
  }
  $router.push({ name: 'myListing' });
}
</script>

<template>
  <div class="production-wrap">
    <div class="head-wrap">
      <!-- 在售数量 -->
      <div class="sale-num">
        <!-- 国庆前关闭（已开启） -->
        {{ marketStore.totalNum ? marketStore.totalNum : 0 }} <span>{{ $t('panel.24') }}</span>
        <!-- 0 <span>{{ $t('panel.24') }}</span> -->
      </div>

      <div style="display: flex; align-items: center; height: 100%">
        <!-- 我的挂单 -->
        <div class="nav-item" @click="handleOrder" v-if="appStore.curDevice !== 'phone'">
          {{ $t('panel.27') }}
        </div>
        <!-- 下拉选择排序 -->
        <div class="select-wrap" @mouseenter="enterTrade" @mouseleave="leaveTrade">
          <div class="opt-content">{{ $t(options[marketStore.publicParams.sort].label) }}</div>
          <i class="iconfont icon-simple-arrow"></i>

          <!-- 选项 -->
          <transition name="el-zoom-in-top">
            <div class="option-wrap" v-show="showSelPanel">
              <li v-for="opt in options" :key="opt.value" @click.stop="selectChange(opt)">
                {{ $t(opt.label) }}
              </li>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- 商品布局 -->
    <Grid />
    <!-- 分页器 -->
    <div class="pagtination-wrap">
      <el-pagination
        v-model:current-page="marketStore.publicParams.page"
        background
        layout="prev, pager, next"
        @current-change="changePage"
        :total="marketStore.totalNum"
        :page-size="20"
        v-show="marketStore.totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.production-wrap {
  $navHeight: 1.5rem; // 导航栏高度
  $asideWidth: 1.28rem; // 侧边栏宽度

  // background-color: #000;
  color: #fff;
}

.head-wrap {
  @include flexPos(space-between, center);
  height: 0.5rem;
  margin: 0.5rem 0.7rem 0.3rem 0.11rem;

  @media (max-width: $phone) {
    margin: 0.22rem 0.42rem;
  }
  .sale-num {
    font-size: 0.36rem;
    font-weight: 600;
    white-space: nowrap;

    span {
      // margin-left: 0.1rem;
      font-weight: 500;
    }
  }

  .nav-item {
    background-color: #fff;
    font-size: 0.16rem;
    width: 1.24rem;
    height: 0.35rem;
    @include flexPos(center);
    border-radius: 1.24rem;
    cursor: pointer;
    color: #000;
    margin-right: 0.68rem;
  }
}

// 下拉选择排序
.select-wrap {
  @include -width(auto, auto, 1.93rem);
  // height: 0.35rem;
  border: solid 1.5px #fff;
  border-radius: 5px;
  padding: 0.06rem 0.12rem;
  @include -media($phone, padding, 0.1rem 0.12rem);
  @include flexPos(space-between);
  @include -font-size(0.24rem, 0.24rem, 0.16rem);
  position: relative;
  cursor: pointer;
  z-index: 6;

  .iconfont {
    transform: rotate(-90deg);
    @include -media($phone, margin-left, 0.1rem);
  }

  .option-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0.32rem;
    @include -media($phone, top, 0.5rem);
    background-color: #030114;
    padding: 0.06rem 0.12rem;
    border-radius: 5px;
    border: solid 1.5px #fff;

    li {
      padding: 0.1rem 0;

      &:not(:last-child) {
        border-bottom: solid 1.2px #6e6e7a;
      }
    }
  }
}

// 分页器
.pagtination-wrap {
  @include flexPos(center);
  margin: 0.5rem;

  :deep(){

    .el-input__inner {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }
  
    button {
      background-color: transparent !important;
    }
  
     .el-pager {
      li {
        background-color: transparent !important;
      }
    }
  
    .el-pagination.is-background .el-pager li {
      background-color: black;
      color: white;
      @include -font-size(0.4rem, 0.4rem, 0.2rem);
      opacity: 0.2;
      // border: 1px solid #656565;
  
      &:not(.disable).active {
        background-color: transparent;
        font-weight: bold;
        opacity: 1;
      }
    }
  
   .el-pagination.is-background .btn-next {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }
  
     .el-pagination.is-background .btn-prev {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }
  
     .el-icon {
      @include -font-size(0.4rem, 0.4rem, 0.2rem);
      font-weight: bold !important;
    }
  } 
}
</style>
