<!-- 单个卡牌组件 -->
<script setup>
import { ref } from "vue";
import { computed, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getFullNum, getTradeTypeCoin } from "@/utils/tools";
import { ElMessage } from "element-plus";
import useMarketStore from "@store/cattleMarketStore";
import useCattleMintStore from "@contractStore/cattleMintStore";
import { useI18n } from "vue-i18n";
import { $GET } from "../../../../../service/require";

const $tx = useI18n();
const marketStore = useMarketStore();
const cattleMintStore = useCattleMintStore();
cattleMintStore.createContract();
// 卡牌页面内容
const props = defineProps(["cardItem"]);
// 卡牌状态 false 在售 true 已售
const cardStatus = ref(false); // false 在售 true 已售
const subClass = computed(() => {
  switch (marketStore.curPickCate) {
    case "blindBox":
      return 1;

    case "calf":
      return props.cardItem.class;

    case "planet":
      return props.cardItem.types;
  }
});
/**
 * 路由跳转详情
 * @param {Number} id 卡牌ID
 * @param {Number} tradeType 支付币种类型
 * @param {Number} price 价格
 * @param {String} type 类型：盲盒、牛牛、星球
 * @param {Number} cls 详细类型：创世 1 普通 2； 家园 1 联邦 2 拓荒 3
 * @param {String} holder 卖家
 */
const router = useRouter();
const route = useRoute();
async function toDetail() {
  const goodsTypes = {
    calf: 1,
    blindBox: 2,
    planet: 3
  };
  const goodsType = goodsTypes[marketStore.curPickCate];
  let status = await cattleMintStore.getNftCardStatus(
    goodsType,
    props.cardItem.id
  );
  if (status) {
    cardStatus.value = true;
    ElMessage($tx.t("detail.32"));
    return;
  }
  router.push({
    name: "nftMarketDetail",
    query: {
      id: props.cardItem.id,
      from: route.name,
      tradeType: props.cardItem.tradeType,
      price: props.cardItem.price,
      doller: props.cardItem.usdt,
      type: marketStore.curPickCate,
      cls: subClass.value,
      holder: props.cardItem.holder
    }
  });
}

// 头部展示的名字
const headName = computed(() => {
  switch (marketStore.curPickCate) {
    case "blindBox":
      return $tx.t("card.1");

    case "calf":
      return props.cardItem.class == 1
        ? `${$tx.t("card.3")} #${props.cardItem.id}`
        : `${$tx.t("card.4")} #${props.cardItem.id}`;

    case "planet":
      return $tx.t("card.12");
  }
});

// 消耗的币
const coin = getTradeTypeCoin(props.cardItem?.tradeType);
</script>

<template>
  <div :class="`card-wrap ${cardStatus?'sold':''}`" @click="toDetail">
    <div v-if="cardStatus" class="status">{{$tx.t('detail.32')}}</div>
    <header>{{ headName }}</header>
    <div class="gender" v-if="marketStore.curPickCate === 'calf'">
      <i :class="['iconfont', props.cardItem?.gender === 1 ? 'icon-nan' : 'icon-nv']"></i>
    </div>

    <!-- 盲盒图片 -->
    <img
      src="https://game.legendranch.app/legendaryranch/boxgif/box.png"
      alt
      class="face"
      v-if="marketStore.curPickCate === 'blindBox'"
    />

    <!-- 星球图片 -->
    <img
      src="https://game.legendranch.app/legendaryranch/planet/homeland.png"
      alt
      class="face"
      v-else-if="marketStore.curPickCate === 'planet'"
    />

    <!-- 牛牛图片 -->
    <img
      :src="props.cardItem.image"
      alt
      class="face"
      v-else-if="marketStore.curPickCate === 'calf'"
    />

    <!-- 价格 -->
    <div class="price-container">
      <div :class="['market-coins', `icons-${getTradeTypeCoin(props.cardItem?.tradeType)}`]"></div>
      <div class="price-content">{{ getFullNum(Number(props.cardItem.price).toFixed(3)) }}</div>
      <div class="coin-name">{{ getTradeTypeCoin(props.cardItem?.tradeType)?.toUpperCase?.() }}</div>
    </div>
    <!-- 美元 -->
    <!-- <div class="doller">≈${{ props.cardItem.usdt }}</div> -->
  </div>
</template>

<style lang="scss" scoped>
.card-wrap {
  padding: 0 0.18rem 0.16rem;
  @include flexPos(space-between);
  flex-direction: column;
  position: relative;
  &.sold {
    cursor: not-allowed !important;
  }
  .status {
    position: absolute;
    right: 0;
    top: 0;
  }
}

header {
  margin: 0.13rem auto 0;
  text-align: center;
  font-size: 0.18rem;

  @media (max-width: $phone) {
    font-size: 0.28rem;
    line-height: 0.33rem;
  }
}
.gender {
  margin: 0.07rem auto;
  @include flexPos(center);

  .iconfont {
    font-size: 0.2rem;
    @include -media($phone, font-size, 0.29rem);
  }
}

.face {
  // width: 100%;
  @include -width(2.64rem, 2.64rem, 1.84rem);
}

.price-container {
  @include flexPos(center);
  @include -font-size(0.24rem, 0.24rem, 0.18rem);
  @include -media($phone, line-height, 0.28rem);
  .price-content {
    max-width: 1.5rem;
    text-align: end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0.04rem 0 0.06rem;
  }
}
.market-coins {
  @include -width(0.3rem, 0.3rem, 0.24rem);
  @include -height(0.3rem, 0.3rem, 0.24rem);
}
.doller {
  margin: 0 auto;
  text-align: center;
  @include -font-size(0.22rem, 0.22rem, 0.18rem);
}
</style>
